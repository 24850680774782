var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"security-container"},[_c('reload-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'overview'),expression:"activeTab === 'overview'"}],staticStyle:{"position":"absolute","right":"16px","top":"13px","z-index":"999"},attrs:{"show-text":false},on:{"click":_vm.overviewRefresh}}),_c('a-tabs',{attrs:{"animated":false},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('a-tab-pane',{key:"overview",attrs:{"tab":"总览"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"xs":24,"md":12,"xl":6}},[_c('div',{staticClass:"module-container",staticStyle:{"height":"348px"}},[_c('div',{staticClass:"module-header",staticStyle:{"margin-bottom":"16px"}},[_vm._v(" 设备状态统计 ")]),_c('status-card',{attrs:{"source-type":"network_device","countFunc":_vm.countFunc,"type":['firewall', 'security', 'load_balancer']},on:{"changeList":function($event){return _vm.getParams({})},"filterByStatus":v => _vm.getParams({status: v}),"filterAlerts":v => _vm.currentSeverity = v}},[_c('template',{slot:"typeImg"},[_c('img',{attrs:{"src":require("@/assets/icons/security.svg"),"width":"48","height":"48"}})])],2)],1)]),_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"xs":24,"md":12,"xl":6}},[_c('div',{staticClass:"module-container",staticStyle:{"height":"348px"}},[_c('div',{staticClass:"module-header",staticStyle:{"margin-bottom":"16px"}},[_vm._v(" 类型分布图表 ")]),_c('a-spin',{attrs:{"spinning":_vm.spinning['typeChart']}},[(_vm.typeChartData.length)?_c('stacked-column-chart',{attrs:{"height":270,"chart-id":"security-type-chart","chart-data":_vm.typeChartData,"is-severity-chart":true,"size":34}}):_c('div',{staticStyle:{"height":"270px","position":"relative","overflow":"hidden"}},[_c('empty-component',{attrs:{"imgUrl":require('@/assets/images/info_empty.png'),"body-style":{
                    height: '168px',
                    marginTop: '44px'
                  },"description-style":{
                    marginTop: '-8px',
                    marginRight: '8px'
                  },"description":"暂无制造商分布数据"}})],1)],1)],1)]),_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"xs":24,"md":12,"xl":12}},[_c('div',{staticClass:"module-container",staticStyle:{"height":"348px"}},[_c('div',{staticClass:"module-header",staticStyle:{"margin-bottom":"16px"}},[_vm._v(" 类型分布统计 ")]),_c('a-spin',{attrs:{"spinning":_vm.spinning['typeChart']}},[(_vm.typeTabData.length && !_vm.spinning.typeChart)?_c('resource-tab',{attrs:{"source-type":"network_device","dataSource":_vm.typeTabData},on:{"goTable":v => _vm.getParams({device_type: v}),"goTableWithStatus":v => _vm.getParams({device_type: v[0], status: v[1]})}}):_c('div',{staticStyle:{"height":"270px","position":"relative","overflow":"hidden"}},[_c('empty-component',{attrs:{"imgUrl":require('@/assets/images/info_empty.png'),"body-style":{
                    height: '168px',
                    marginTop: '44px'
                  },"description-style":{
                    marginTop: '-8px',
                    marginRight: '8px'
                  },"description":"暂无制造商分布数据"}})],1)],1)],1)]),_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"md":24,"lg":14,"xl":16}},[_c('div',{staticClass:"module-container",staticStyle:{"height":"340px"}},[_c('div',{staticClass:"module-header",staticStyle:{"margin-bottom":"16px"}},[_vm._v(" 当前告警 ")]),_c('severity-radio-group',{staticStyle:{"position":"absolute","top":"16px","right":"20px"},model:{value:(_vm.currentSeverity),callback:function ($$v) {_vm.currentSeverity=$$v},expression:"currentSeverity"}}),_c('alert-timeline',{staticClass:"overflow-scroll-hidden",staticStyle:{"padding":"10px 4px 0 10px","overflow":"auto","height":"265px"},attrs:{"severity":_vm.currentSeverity,"sourceType":"network_device","device-type":['firewall', 'security', 'load_balancer']}})],1)]),_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"md":24,"lg":10,"xl":8}},[_c('div',{staticClass:"module-container",staticStyle:{"height":"340px"}},[_c('div',{staticClass:"module-header"},[_vm._v("累计告警统计")]),_c('div',{staticStyle:{"position":"absolute","right":"20px","top":"17px"}},[_c('simple-select',{attrs:{"options":_vm.datetimeOptions,"value":_vm.datetimeOptions[1]},on:{"input":v => (_vm.selectedDatetime = v.value)}})],1),_c('a-spin',{attrs:{"spinning":_vm.spinning['alert']}},[(_vm.chartData.length)?_c('radial-bar-chart',{style:({
                  paddingTop: '26px',
                  maxHeight: '270px',
                  overflow: 'hidden'
                }),attrs:{"chart-id":"security-severity-chart","chart-data":_vm.chartData,"legendPosition":"right","flip-page":false,"maxValue":null,"height":270,"unit":"","barSize":11,"colors":_vm.severityColors,"legendPositionCenter":true,"annotationHtml":_vm.annotationHtml}}):_c('div',{staticStyle:{"height":"270px","position":"relative","overflow":"hidden"}},[_c('empty-component',{attrs:{"body-style":{
                    height: '216px',
                    marginTop: '36px'
                  },"description-style":{
                    marginTop: '-28px'
                  }}})],1)],1)],1)]),_c('a-col',{staticStyle:{"margin-bottom":"16px"}},[_c('div',{staticClass:"module-container",staticStyle:{"min-height":"410px"}},[_c('div',{staticClass:"module-header",staticStyle:{"margin-bottom":"16px"}},[_vm._v(" 设备性能TOP ")]),_c('high-performance-table',{attrs:{"device-type":['firewall', 'security', 'load_balancer'],"source-type":"network_device","get-func":_vm.getPerfFunc,"scrollY":608}})],1)])],1)],1),_c('a-tab-pane',{key:"list",attrs:{"tab":"列表详情"}},[_c('div',{staticStyle:{"background":"#fff","border-radius":"8px","padding":"24px","box-shadow":"rgba(0, 0, 0, 0.08) 0px 0px 8px","position":"relative"}},[_c('network-device-table',{attrs:{"refresh":_vm.refresh,"tools-list":['setting', 'reload', 'search', 'leftTool', 'export'],"device-type":_vm.securityType,"fetch-params":_vm.fetchParams}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }